:root{
  --color1: rgb(29, 53, 87);
  --color2:rgb(69, 123, 157);
  --color3: rgb(230, 57, 70);
  --color4: rgb(219, 79, 90);
  --colorFondo1:white;
  --color-text:white;
  --font-size:36px;
}
#root, .App{
  height: 100%;
}

body{
    margin: 0px;
    padding: 0px;
    width: 100vw;
    height: 100vh;
    /* font-family: Arial, Helvetica, sans-serif; */
}

.container-row{
    width: auto;
    height:40px;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
}
.container-room-common-monitor .container-row{
  height: 60px;
}
.container-room-common .container-row{
  height: 60px;
}

.even{
    background-color: var(--color2) !important;
    color: white;
}
.odd{
    background-color: var(--color1) !important;
    color: white;
}

.container-colums-row{
    /* background-color: lightpink; */
    font-size: 35px;
    width: 100%;
    display: inline-flex;
    padding: 10px;
}

.container-colums-row .left{
  width: 50%;
  padding-left: 33px;
}
.container-colums-row .right{
  width: 50%;
  text-align: end;
  padding-right: 33px;
}


/* Toggle button */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
  }
  
  #toggleInputButton:checked + .slider {
    background-color: #2196F3;
  }
  
  
  
  #toggleInputButton:checked + .slider:before {
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

  /* end toggle Button */



  .loggin-room-container{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color1);
    z-index: 0;
  }

  /* Beginning form */
  .loggin-room-container form{
    width:90vw;
    max-width:768px;
    /* border:1px solid #ddd; */
    /* padding:3vw; */
    display:flex;
    flex-direction:column;
    border-radius:5px;
}

.loggin-room-container label{
    margin-bottom:15px;
    position:relative;
    /* border-bottom:1px solid #ddd; */
    color:var(--color-text) ;
    font-size: var(--font-size);
    display: flex;
    justify-content: center;
    align-items: center;
}
.loggin-room-container input{
    width:100%;
    /* padding:10px 0px; */
    /* margin-top:20px; */
    /* border:none !important;
    outline:none !important; */
    background-color: var(--color1);
    font-size: var(--font-size);
    color: var(--color-text);
    text-align: center;
}
.loggin-room-container input::placeholder{
    opacity:0;
    
}
.loggin-room-container span{
    position:absolute;
    top:0;
    left:0;
    transform:translateY(30px);
    transition-duration:300ms;
    width: 100%;
    text-align: center;
}
.loggin-room-container button{
  background: var(--color1);
  color: var(--color-text);
  border: 1px solid var(--color-text);
  cursor: pointer;
  border-radius: 43px;
  font-size: var(--font-size);
  width: 50%;
  margin: auto;
  height: 73px;
}

.loggin-room-container label:focus-within > span,
.loggin-room-container input:not(:placeholder-shown) + span{
    /* color:purple; */
    transform:translateY(-63px);
}
#button-acces-room-user{
  display: none;
}
/* end form */

#button-acces-app-user-confirm{
  /* display: none; */
  cursor: pointer;
}
#text-acces-app-user-confirm{
font-size: 30px;
padding: 20px;
}
.container-confirm-user{
  display: block;
  text-align: center;
}

/* logging manager form */
.logging-manager-container input[type=text],.logging-manager-container input[type=number], select,.logging-manager-container input[type=password] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.logging-manager-container input[type=submit] {
  width: 100%;
  background-color: var(--color3);
  color: white;
  padding: 14px 20px;
  margin: 23px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  /* top: 14px; */
  position: relative;

}

.logging-manager-container input[type=submit]:hover  {
  background-color: var(--color4);
}

.logging-manager-container {
  /* border-radius: 5px; */
  background-color: var(--color1);
  /*! padding: 20px; */
  min-height: 100vh;
  display: flex;
  justify-content: center; 
  align-items: center;
  color: var(--color-text);
}
.logging-manager-container h3 {
  margin-bottom: 14px !important;
  text-align: center;
  margin: 0px;
}
/* end logging manager form */


.container-manager{
  background-color: var(--colorFondo1);
  min-height: 100vh;
  color: var(--color-text);
}
.container-room-manager{
  height: 129px;
  background-color: var(--color2);
  /* margin-inline: 40px; */
  margin-bottom: 5px;
  top: 33px;  
  width: auto;
  
}
.container-room-manager .text{
  padding-inline: 21px;
  padding-bottom: 7px;
  position: relative;
  top: 16px;
  font-weight: bold;
}


.contenedor-boton-volver{
  border-radius: 36px;
  background-color: rgb(255, 255, 255);
  box-shadow:0px 2px 17px #0000003d;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  margin: 10px;
  z-index: 1;
  left:7px;
  cursor: pointer;

}

.icono-boton-volver{
  position: relative;
  left: 4px;
}

.title-rooms-manager{
  color: black;
  margin: 0px;
  text-align: center;

}

.boton-flotante-arriba-derecha{
  border-radius: 36px;
  background-color: rgb(255, 255, 255);
  box-shadow:0px 2px 17px #0000003d;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  margin: 10px;
  z-index: 1;
  right: 0;
  cursor:pointer;
}

.edit-boton{
  border-radius: 36px;
  background-color: rgb(255, 255, 255);
  box-shadow:0px 2px 17px #0000003d;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  z-index: 1;
  right: 0;
}



.boton-pequenio{
  display: inline;
  width: 100%;
  width: strech;
  width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
  width: -moz-available; 
  text-align: center;
  margin: 0px;
  /* background-color: red; */


}
.boton-pequenio .contenedor-centrado-icono{
  display: flex;
  justify-content: center;
  align-items: center;
}
.boton-pequenio  .texto{
  margin-top: 11px;
}

.boton-pequenio .contenedor-icono{
  height: 43px;
  width: 43px;
  border-radius: 60px;
  box-shadow:0px 2px 17px #0000003d;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-text);
  cursor:pointer;
}

.bloque4{
  display: flex;
  justify-content: center;
  align-items: center;
  padding-block: 18px;
  width: 41%;
  margin: auto;
}

.container-colums-row-editable .left{
  width: 30%;
  display: flex;
align-items: center;
}
.container-colums-row-editable .right{
  width: 30%;
  text-align: start;
  display: flex;
align-items: center;
}
.container-colums-row-editable .name{
  width: 100%;
  text-align: start;
  display: flex;
align-items: center;
}
.container-colums-row-editable{
    /* background-color: lightpink; */
    font-size: 20px;
    width: 100%;
    display: inline-flex;
    padding: 10px;
}
.container-colums-row-editable.new{
  background-color: var(--color3);
  justify-content: center;
  cursor: pointer;
}
.container-colums-row-editable.new:hover{
  background-color: var(--color4);
}
.container-colums-row-editable .contenedor-icono{
  position: relative;
  top:2px;
  
}
.container-colums-row-editable .fill{
  fill:var(--color-text);
}

.container-colums-row-editable .fill:hover{
  fill:rgb(227, 236, 240);
}

.boton-pequenio-editable{
  display: inline;
width: 9%;
}
.boton-pequenio-editable .contenedor-centrado-icono{
  display: flex;
  justify-content: flex-end;
}
.boton-pequenio-editable .contenedor-centrado-icono svg{
  cursor: pointer;
}

.boton-pequenio-editable .active svg{
  fill: var(--color3) !important;
}
.container-edit-room{
  min-height: 100vh;
  background-color: var(--colorFondo1);
}

.container-edit-room-rows{
  width: 70%;
  margin: auto;
  position: relative;
  top: 40px;
}

.container-row.margin-botom{
  margin-bottom: 6px;
  color: var(--color-text);
}

.container-2-manager{
  /* background-color: red; */
  position: relative;
  top: 40px;
  width: 70%;
  margin: auto;
}

.disable-button{
  pointer-events: none;
  cursor: default;
  opacity: 0.2;
}

.half-width{
  width: 50%;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #09f;

  animation: spin 1s ease infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.contenedor-spinner{
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: red; */
  width: 100vw;
  height: 100vh;
  z-index: 3;
}

.container-room-common-monitor{
  width: 100%;
  display: flex;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  min-height: 100vh;
  background-color: var(--colorFondo1);

}
.container-room-common-monitor .container-center{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
}
.container-room-common-monitor .room-ids-container{
/* background-color: red; */
/* width: 50% */
font-size: 24px;
}
.container-room-common-monitor .rows-container{
width: 75%;
}

.container-room-common-monitor .room-ids-container .qr-id{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container-room-common-monitor .room-ids-container .text-id{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container-room-common-monitor .room-ids-container .web{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 6px;
 
}

/* Force input background color seet white, thought there are autocomplete */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}



.input-loggin-room{
  border-radius: 43px !important;
  width: 50% !important;
}

.liver-in-loggin-room{
  position: absolute;
  right: 0px;
  bottom: 0px;
  color: var(--color-text);
  padding: 40px;
}

a{
  color: inherit;
text-decoration: none;
}

.shop-container{
  /* background-color: var(--color1); */
  position: absolute;
  height: 100%;
  width: 100%;
}


.form-card-pay{
  width: 50%;
  background-color: var(--color1);
  padding: 30px;
}
.card-element{
  background-color: white;
  border: #dfdfdf;
  border-style: solid;
  border-width: 1px;
  padding: 12px;
  border-radius: 4px;
  margin-top: 9px;
  margin-bottom: 9px;
}

.formulario-centrado .container-subscription{
  display: block;
  font-size: 30px;
  width: 50%;
  text-align: center;
}

.formulario-centrado .container-subscription .buttons{

  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  font-size: 25px;
  /* background-color: red; */
}
.formulario-centrado .container-subscription .buttons .create{
  border: white;
  border-width: 1px;
  border-radius: 54px;
  border-style: solid;
  cursor: pointer;
  padding: 5px;
  width: 35%;
  margin-right: 3px;
  width: 50%;
  /* margin: auto; */
}
.formulario-centrado .container-subscription .buttons .next{
  /* border: white;
  border-style: solid; */
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding-left: 37px;

}
.formulario-centrado .container-subscription .small{
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

a{
  cursor: pointer;
}

.container-settings{
  background-color: var(--color1);
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  color:var(--color-text);
  font-size: 30px;
  width: 100%;
}
.container-settings .options{
display: block;
/* width: 100%; */
cursor: pointer;

}

.container-pay-success{
  background-color: var(--color1);
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  color:var(--color-text);
  font-size: 30px;
  width: 100%;
}
.container-pay-success .options{
display: block;
/* width: 100%; */
/* cursor: pointer; */

}
.container-pay-success .text{
  display: block;
  /* width: 100%; */
  /* cursor: pointer; */
  font-size: 20px;
  
  }

  .liver-link{
     cursor: pointer;
  }

.container-payments{
  background-color: var(--color1);
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  color:var(--color-text);
  font-size: 30px;
  width: 100%;
}
.container-payments .options{
  display: block;
  /* width: 100%; */
  cursor: pointer;

}
.container-payments .options .text{
  font-size: 40px;
}
.container-payments .options .date{
  font-size: 23px;
}
.container-payments .options .text{
  font-size: 40px;
}
/* .container-payments .options .item:hover{
  font-size: 30px;
} */
/* .container-settings .options .item:hover{
  font-size: 39px;
} */


.invoice-container{
  display: flex;
  /* background-color: red; */
  padding-bottom: 30px;
  width: 40%;
}
.invoice-container div{
  padding-block: 4px;
}

.invoice-container .left{
  width: 100%;
}
.invoice-container .right{
  width: 100%;
  text-align: end;
}

.red-color{
  color:var(--color3) !important;
}

.result-new-user-form{
  padding-block: 7px;
}

.forgot-your-password{
  display: block;
  padding-top: 8px;
}

.terms-container{
  background-color: white;
  position:absolute;
  top: 0;
}
.terms-text-container{
  position: relative;
  margin: 90px 20px;
}

.print-icon-edit-turn{
  padding: 3px;
  font-size: 50px;
  width: 44px;
  fill: white;
  height: 35px;
  margin-inline: 13px;
  cursor: pointer;
}
.print-icon-edit-turn:hover{
  fill:gainsboro;
}
.container-turn-id-print-icon{
  display: flex;
  align-items: center;
}

.title-room-not-exist{
  color: white;
  font-size: 3em;
  text-align: center;
  padding: 20px;
}

.loggin-room-container .error-container-center{
  display: flex;
  justify-content: center;
  align-items: center;
} 
.loggin-room-container .error-container-center .try-again{
  /* background-color: red; */
  text-align: center;
  color: white;
  font-size: 1.5em;
  cursor: pointer;
  padding: 10px;
}