:root {  
    --fondo:white;
    /* --color1:#FF585D;
    --color2:rgb(71, 84, 104);
    --color3:rgb(237,237,237); */
    --color1: rgb(29, 53, 87);
    --color2:rgb(69, 123, 157);
    --color3: rgb(230, 57, 70);
    --color4: rgb(219, 79, 90);
    --colorFondo1:white;
    --color2transparecia:rgba(29, 53, 87, 0.822);
    --color-text:white;
  }
.transparecia-fondo-izquierda{
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.9);
  }
.pantalla-completa{
    /* background-color: red; */
    min-height: 100vh;
    display: flex;
}

.pantalla-mitad{
    /* background-color: rosybrown; */
    width: 100vw;
}

.contenedor-foto-portada-izquierda{
    min-height: 100%;
    background-image: url("./multimedia/foto7.jpg");
    background-repeat:no-repeat;
    -webkit-background-size:cover;
    -moz-background-size:cover;
    -o-background-size:cover;
    background-size:cover;
    background-position:center;
    position: relative;
}
.contenedor-foto-portada-derecha{
    min-height: 100%;
    background-image: url("./multimedia/foto5.png");
    background-repeat:no-repeat;
    -webkit-background-size:cover;
    -moz-background-size:cover;
    -o-background-size:cover;
    background-size:cover;
    background-position:center;
}
.texto-portada-izquierda{
    background-color: rgba(255, 255, 255, .4);
    /* width: 400px; */
    width: 83%;
    min-height: 62%;
    position: absolute;
    bottom: 0;
    right: 0;
    border-left: 24px solid var(--color2);
    padding-left: 14px;
    padding-right: 14px;
}
.texto-grande-portada-izquierda{
    color: var(--color1);
    font-size:60px;
    font-weight: 700;
}
.texto-medio-portada-izquierda{
    color: var(--color2);
    font-size:40px;
    font-weight: 300;
}
.texto-pequeno-portada-izquierda{
    color: var(--color2);
    font-size:20px;
    font-weight: 200;
    padding-bottom: 13px;
    padding-top: 17px;
}
.texto-suelto-ancho-completo{
    background-color: var(--color1);
    min-height: 300px;
    display: -webkit-flex;
    /* display: flex; */
    align-items: center;
    justify-content: center;
    padding: 18px;
}
.texto-suelto-trabajos-realizados{
    background-color: var(--color3);
    min-height: 300px;
    display: -webkit-flex;
    /* display: flex; */
    align-items: center;
    justify-content: center;
}
.texto-dentro-imagen-planificacion{
    min-height: 446px;
    display: -webkit-flex;
    /* display: flex; */
    align-items: center;
    justify-content: center;
    background-color: var(--color2transparecia);
}
.texto-suelto-ancho-completo-color{
    background-color: var(--color1);
    min-height: 300px;
    display: -webkit-flex;
    /* display: flex; */
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 9px;
}
.numero-planificacion{
    font-size: 150px;
    color: var(--color3);
    position: absolute;
    font-weight: 600;
}
.texto-numero-planificacion{
    font-size: 59px;
    color: white;
    position: absolute;
    text-shadow: 0px 3px 20px #000000;
    font-weight: 400;
}
#texto1{
    color: var(--color-text);
    font-size: 50px;
    text-align: center;
    /* width: 100%; */
    font-weight: 400;
}
#texto2{
    /* width: 100%; */
    font-size: 20px;
    color: var(--color2);
    text-align: center;
    font-weight: 200;
}
#texto3{
    /* width: 100%; */
    font-size: 50px;
    color: white;
    text-align: center;
    font-weight: 400;
}
.texto4{
    font-size: 25px;
    color: var(--color2);
    text-align: left;
    padding-bottom: 4%;
    font-weight: 200;
}
.texto5{
    font-size: 20px;
    color: var(--color1);
    text-align: left;
    font-weight: bold;
    padding-bottom: 1%;
}
.texto6{
    font-size: 20px;
    color: var(--color2);
    text-align: left;
    padding-bottom: 5%;
}

.texto7{
    font-size: 21px;
    color: var(--color2);
    text-align: left;
    padding-bottom: 5%;
    font-weight: 200;
}
.texto8{
    font-size: 18px;
    color: var(--color2);
    text-align: left;
}
.texto9{
    font-size: 38px;
    color: var(--color2);
    text-align: center;
    padding-top: 25px;
    padding-bottom: 18px;
    font-weight: 500;
}
.icono-planificacion{
    width: 29px;
    color: var(--color3);
    position: relative;
    top: 6px;
}
.texto-cambio-color1{
    color: var(--color1);
}
.texto-cambio-color2{
    color: var(--color2);
}
.texto-cambio-color3{
    color: var(--color3);
}
.contenedor-item-foto-texto-planificacion{
    padding: 32px;
}
#boton-contactar{
    background-color: var(--color1);
    /* border: 2px solid white; */
    color: white;
    border-radius:5px;
    padding: 10px;
    margin: 20px;
    font-size: 20px;
    text-decoration: none;
    position: relative;
    top: 30px;
}
.foto-texto-mitad{
    /* background-color: red; */
    display: flex;
}
.item-foto-texto-mitad{
    /* background-color: blue; */
  
    text-align: left;
    padding: 5%;
    width: 100%;
}

.foto-texto-planificacion{
    /* background-color: red; */
    display: flex;
}
.item-foto-texto-planificacion{
    background-color: white;
  
    text-align: left;
    /* padding: 5%; */
    width: 100%;

    
    
}

.imagen-texto-planificacion{
    min-height: 100%;
    background-repeat:no-repeat;
    -webkit-background-size:cover;
    -moz-background-size:cover;
    -o-background-size:cover;
    background-size:cover;
    background-position:center;
    position: relative;
}
#imagen-texto-planificacion1{
    /* background-image: url("./multimedia/foto1.jpeg"); */
    background-image: url("./multimedia/foto1.png"); 
}
#imagen-texto-planificacion2{
    /* background-image: url("./multimedia/foto2.jpg"); */
    background-image: url("./multimedia/foto2.png");
}
#imagen-texto-planificacion3{
    /* background-image: url("./multimedia/foto3.jpg"); */
    background-image: url("./multimedia/foto3.png"); 
}

body{
    /* Broke roomCommon */
    /* background-color: var(--color1); */

    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu;
    /* font-family:Arial,Helvetica,sans-serif; */
    font-weight: lighter;
}

.fila-caracteristicas-iconos{
    /* background-color: lightseagreen; */
    display: flex;
}
.item-caracteristicas-iconos{
    /* background-color: lime; */
    display: flex;
    /*! padding: 3%; */
    width: -webkit-fill-available;
    width: 100%;
    /* background-color: gray; */
}

.icono{
    /* padding-right: 4%; */
    min-width: 56px;
    max-width: 56px;
    fill: var(--color2);
}
.titulo-item-icono{
    color: var(--color1);
    font-size: 25px;
    font-weight: 300;
}
.texto-item-icono{
    color: var(--color2);
    font-size: 18px;
    font-weight: 200;
}
.imagen-adaptada-contenedor{
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;

}
.item-foto-suelta{
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
    min-width: 100vw;
}
.fotos-tecnologias{
    text-align: center;
}
.fotos-realizados{
    text-align: center;
}
.item-fotos-tecnologias{
    width: 133px;
}
.item-fotos-realizados{
    width: 133px;
    /* -webkit-filter: grayscale(100%);  */
    /* filter: grayscale(100%); */
    padding: 6px;
}
.contenedor-icono{
}
.contenedor-fila-caracteristicas-iconos{
    padding: 3%;
    width: 100%;
}
.bloque-texto-item-caracteristicas-iconos{
    padding-left: 10px;
}
@media screen and (max-width: 769px) {
    .contenedor-formulario {
        width: 86% !important;
        /* background-color: red; */
    }
    .contenedor-foto-portada-izquierda{
        /* background-color: rosybrown; */
        /* width: 100vw; */
        /* float: left; */
        min-height: 100vh;
    }

    /* #foto-portada-derecha{
        display: none;
    } */

    .pantalla-completa{
        /* background-color: red; */
        min-height: 100vh;
        display: grid;
    }

    .contenedor-foto-portada-derecha{
        min-height: 42vh;
        background-image: url("./multimedia/foto5.png");
        background-repeat:no-repeat;
        -webkit-background-size:cover;
        -moz-background-size:cover;
        -o-background-size:cover;
        background-size:cover;
        background-position:center;
    }
    .fila-caracteristicas-iconos {
        /* background-color: lightseagreen; */
        display: grid;
    }
    .item-caracteristicas-iconos {
        width: 100%;
        /* background-color: lime; */
        display: flex;
        /* background-color: red; */
        /* padding: 4%; */
    }
    .item-foto-texto-mitad{
        /* background-color: blue; */
        width: auto;
        text-align: left;
        padding: 4%;
    }
    .foto-texto-mitad{
        /* background-color: red; */
        display: grid;
    }
    .item-foto-texto-planificacion{
        background-color: white;
        width: auto;
        text-align: left;
    }
    .foto-texto-planificacion{
        /* background-color: red; */
        display: grid;
    }
    .icono{
        /* min-width: 11%; */
    }
    .contenedor-fila-caracteristicas-iconos {
        padding: 3%;
        width: auto;
    }
}
/* Unicamente para el la primera pagina de dispositivos muy pequenos */
@media screen and (max-width: 325px){
    .texto-grande-portada-izquierda{
        font-size: 44px;
    }
    .contenedor-formulario{
    }
    
    
}


.imagen-adaptada-contenedor-planificacion{
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    overflow: hidden;
}
.contenedor-formulario{
    margin-top: 96px !important;
    position: relative;
    margin-bottom: 50px !important;
}
















/* header */
.site-header{
    display: flex;
    justify-content: center;
    background-color: var(--color1);
    z-index: 2;
    box-shadow: 0px -9px 20px 0px;
    position: fixed;
    width: 100vw;

}
.topnav {
    /* overflow: hidden; */
    background-color: var(--color1);
    
    width: 87%;

    display: flex;
    /* justify-content: space-between; */
  }
  
  
  .topnav a {
    float: left;
    display: block;
    color: #f2f2f2;
    text-align: center;
    padding: 19px 16px;
    text-decoration: none;
    font-size: 17px;
    height: 28px;
    align-self: center;
    display: flex;
    align-items: center;
  }
  
  .topnav a:hover {
    background-color: var(--color1);
    /* color: black; */
  }
  
  .topnav a.active {
    background-color: var(--color1);
    color: white;
    height: 28px;
    align-self: center;
    display: flex;
    align-items: center;
    vertical-align: middle;
  }
  
  .topnav .icon {
    display: none;
  }
  
  @media screen and (max-width: 769px) {
    .topnav a:not(:first-child) {display: none;}
    .topnav a.icon {
      float: right;
      display: block;
    }
    .topnav{
        width: 98%;
    }
    /* Reordena los elementos */
    #titulo-planificacion1{
        order: 1;
    }
    #texto-planificacion1{
        order:2;
    }
    #titulo-planificacion2{
        order: 1;
    }
    #texto-planificacion2{
        order:2;
    }
    #titulo-planificacion3{
        order: 1;
    }
    #texto-planificacion3{
        order:2;
    }
  }
  
  @media screen and (max-width: 769px) {
    .topnav.responsive {
        position: relative;
        z-index: 2;
    }
    .topnav.responsive .icon {
      position: absolute;
      right: 0;
      top: 0;
    }
    .topnav.responsive a {
      float: none;
      display: flex;
      text-align: left;
    }
  }
  .icono-hamburguesa{
      height: 28px;
  }


  /* formulario */
  
      
  input[type=text], select, textarea, formulario-centrado input {
    width: 100%;
    padding: 12px;
    border: none;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
  }
  
  input[type=submit] {
    background-color: var(--color3);
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
  }
  
  input[type=submit]:hover {
    background-color: var(--color4);
  }
  
  .contenedor-formulario {
    border-radius: 5px;
    /* background-color: var(--color2); */
    padding: 20px;
    width: 50%;
    background-color: var(--color1);
    margin: auto;
    /* border: white; */
    /* border-style: solid; */
    /* border-width: 1px; */
  }
  .contenedor-boton-formulario{
    width: 100%;
    /* background-color: red; */
    text-align: center;
  }
  .formulario-centrado{
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;

    /* background-image: url("./multimedia/foto9.jpg"); */
    background-repeat:no-repeat;
    -webkit-background-size:cover;
    -moz-background-size:cover;
    -o-background-size:cover;
    background-size:cover;
    background-position:center;
    position: relative;
    background-color: var(--color1);
  }
  .opacidad-formulario-fondo{
      /* background-color: rgb(71 84 104 / 33%); */
      background-color: var(--color1);
      width: 100vw;
      min-height: 100vh;
      display: flex;
  }
  #logo-about-menu{
      margin-right:auto;
      font-weight: bolder;
  }

  #video-demostracion{
    width: 99%;
    height: 100vh;
    margin: auto;
    display: flex;
    border:none;
  }
  .container-iframe{
      background-color: black;
      /* width: 800px;
      height: 450px; */
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--color1);
      padding-bottom: 40px;
      padding-inline: 14px;

  }

  .video{
    max-width: 90%;
    max-height: 20%;
    background-color: black;
  }

  .footer{
    display: flex;
    background-color: var(--color1);
    color: white;
    justify-content: center;
    height: 181px;
    position: relative;
    align-items: center;
  }

  .footer .item-footer{
      text-align: center;
        font-size: 30px;
        padding: 2px;
        cursor: pointer;
  }
  .footer .line{
    background-color: white;
    width: 50%;
    height: 1px;
  
  }
  .footer .copyright{
      font-weight: lighter;
      font-size: 12px;
      text-align: center;
      color: rgb(128, 165, 189);
      padding: 4px;
  }